import { SplitText } from "@cyriacbr/react-split-text";
import * as React from "react";
import Helmet from "react-helmet";
import gsap from "gsap";
import Button from "../components/utils/button";
import eventBus from "../components/utils/eventBus";
import qs from "query-string";

/** Import Image */
import testingImg from "../images/testing-image.jpg";

import headerImgDesktop from "../images/src/pages/contact/contact_desktop.jpg";
import headerImgDesktopR from "../images/src/pages/contact/contact_desktop@2x.jpg";

import headerImgMobile from "../images/src/pages/contact/contact_mobile.jpg";
import headerImgMobileR from "../images/src/pages/contact/contact_mobile@2x.jpg";

import "../styles/contact/contact.scss";
class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.domRef = React.createRef();
    this.state = {
      name: "",
      prenom: "",
      email: "",
      numero: "",
      sujet: "",
      message: "",
      feedbackMsg: "",
    };
  }

  componentDidMount() {
    console.log(this.state);
    gsap.set(".contactHeader", { opacity: 0 });
    eventBus.on("Finalised", this.loadMotion); 
  }

  submitForm(){
    let buttonSubmit = document.querySelector('button[type="submit"]');
    this.domRef.current.requestSubmit(buttonSubmit);
  }

  loadMotion = () => {
    gsap.set(".contactHeader", { opacity: 1 });
    let tl = gsap.timeline();
    tl.fromTo(
      ".contactHeader picture",
      { opacity: 0, y: -20 },
      { opacity: 1, y: 0 }
    );
    tl.fromTo(
      ".contactHeader .secondary-title .line",
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 0.2, duration: 0.6 }
    );
    tl.fromTo(
      ".contactHeader p .line",
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0 }
    );
  };

  handleChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
    console.log(this.state);
    //document.querySelectorAll('label').classList.remove('notEmpty');
    if (e.target && e.target.value.length > 0) {
      document
        .querySelector(`[for="${e.target.name}"]`)
        .classList.add("notEmpty");
    } else {
      document
        .querySelector(`[for="${e.target.name}"]`)
        .classList.remove("notEmpty");
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Contact | Menuiserie Perret</title>
          <meta
            name="description"
            type="description"
            content="Parlez-nous de vos projets, nous sommes des spécialistes du sur-mesure."
          />
        </Helmet>
        <section className="contactHeader">
          <div className="content">
            <h1 className="secondary-title diff">
              <SplitText
                LineWrapper={({ lineIndex, children }) => (
                  <span className={"line wrapper" + " line" + lineIndex}>
                    {children}
                  </span>
                )}
              >
                Parlez nous de votre projet
              </SplitText>
            </h1>
            <p className="diff">
              <SplitText
                LineWrapper={({ lineIndex, children }) => (
                  <span className={"line wrapper" + " line" + lineIndex}>
                    {children}
                  </span>
                )}
              >
                Notre équipe vous écoute et vous conseille afin de concevoir le
                projet dans lequel vous vous sentirez le mieux.
              </SplitText>
            </p>
          </div>
          <picture className="picture">
            <source
              media="(min-width: 770px)"
              srcSet={headerImgDesktopR + " 2x, " + headerImgDesktop + " 1x"}
            />
            <img src={headerImgMobile} srcSet={headerImgMobileR + " 2x, " + headerImgMobile + " 1x"} alt="Image de test"></img>
          </picture>
        </section>
        <section className="contact-form">
          <div className="contact-information">
            <div>
              <h3 className="tertiary-title">Téléphone</h3>
              <a href="">04 72 13 55 50</a>
            </div>
            <div>
              <h3 className="tertiary-title">Email</h3>
              <a href="">info@menuiseriebois.com</a>
            </div>
            <div>
              <h3 className="tertiary-title">Adresse</h3>
              <p>72 avenue du Progès 69680 Chassieu</p>
            </div>
          </div>
          <div className="form-wrapper">
            <form
              id="myForm"
              ref={this.domRef}
              name="ContactForm"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              action="/contact-success"
            >
              <input
                ref="form-name"
                type="hidden"
                name="form-name"
                value="ContactForm"
              />
              <input type="hidden" name="bot-field" />
              <div className="left">
                <label
                  htmlFor="name"
                  className={this.state.name.length > 0 ? "notEmpty" : ""}
                >
                  Nom*
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  required
                ></input>
              </div>
              <div className="right">
                <label
                  htmlFor="prenom"
                  className={this.state.prenom.length > 0 ? "notEmpty" : ""}
                >
                  Prénom*
                </label>
                <input
                  type="text"
                  id="prenom"
                  name="prenom"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  required
                ></input>
              </div>
              <div className="left">
                <label
                  htmlFor="email"
                  className={this.state.email.length > 0 ? "notEmpty" : ""}
                >
                  Email*
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  required
                ></input>
              </div>
              <div className="right">
                <label
                  htmlFor="numero"
                  className={this.state.numero.length > 0 ? "notEmpty" : ""}
                >
                  Numéro de téléphone
                </label>
                <input
                  type="tel"
                  id="numero"
                  name="numero"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                ></input>
              </div>
              <div className="center">
                <label
                  htmlFor="sujet"
                  className={this.state.sujet.length > 0 ? "notEmpty" : ""}
                >
                  Sujet
                </label>
                <input
                  type="text"
                  id="sujet"
                  name="sujet"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                ></input>
              </div>
              <div className="center">
                <label
                  htmlFor="message"
                  className={this.state.message.length > 0 ? "notEmpty" : ""}
                >
                  Message
                </label>
                <textarea
                  type="text"
                  id="message"
                  name="message"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                ></textarea>
              </div>
            </form>
            <Button type="submit" value="Envoyer" form="myForm" onClick={()=>{this.submitForm()}}>
              Envoyer
            </Button>
          </div>
        </section>
      </>
    );
  }
}

export default Contact;
